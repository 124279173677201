<template>
  <div>
<iframe id="inlineFrameExample"
    title="Inline Frame Example"
    width="300"
    height="200"
    :src="$router.history.current.params.report">
</iframe> 
  </div>
</template>
<script>
import "./assessments.scss";

export default {
    components:{ },
    mounted(){
    },
    data(){
      return{
        iframeSrc: this.$router.history.current.params.report
      }
    }
    
};
</script>
<style 
Buttonlang="scss" scoped></style>